@import "https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap";
.react-datepicker-popper {
  z-index: 10;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(248 248 248 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 225 181 / var(--tw-ring-opacity));
  border-radius: .25rem;
  padding: 1rem;
}

@media (width >= 1280px) {
  .react-datepicker-popper:focus-within {
    --tw-border-opacity: 1;
    border-color: rgb(36 36 36 / var(--tw-border-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }
}

.react-datepicker__day {
  cursor: pointer;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  line-height: 18px;
  display: flex;
}

.react-datepicker__day:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.react-datepicker__day:hover, .react-datepicker__day:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.react-datepicker__day--today {
  --tw-bg-opacity: 1;
  background-color: rgb(134 225 181 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.react-datepicker__day--highlighted {
  --tw-bg-opacity: 1;
  background-color: rgb(208 240 202 / var(--tw-bg-opacity));
}

.react-datepicker__day--selected {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-bg-opacity: 1 !important;
  background-color: rgb(52 184 120 / var(--tw-bg-opacity)) !important;
}

.react-datepicker__day--disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: rgb(172 157 231 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(222 222 222 / var(--tw-text-opacity));
}

.react-datepicker__day--disabled:hover {
  --tw-text-opacity: 1;
  color: rgb(222 222 222 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(172 157 231 / var(--tw-bg-opacity));
}

.react-datepicker__week {
  display: flex;
}

.react-datepicker__day-name {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 14px;
  line-height: 18px;
  display: flex;
}

.react-datepicker__day-names {
  display: flex;
}

.juBESy > input {
  max-width: 100%;
  height: 100%;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e1def2;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #e1def2;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #e1def2;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23e1def2' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #e1def2;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --tw-text-opacity: 1;
  color: rgb(67 67 67 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 253 / var(--tw-bg-opacity));
  font-family: "Source Sans 3", sans-serif;
  font-size: 15px;
  line-height: 18px;
}

h1, .h1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 38px;
}

h2, .h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
}

h3, .h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

h4, .h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
}

h5, .h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
}

h6, .h6 {
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}

@media (width >= 1024px) {
  h1, .h1 {
    font-size: 32px;
    line-height: 42px;
  }

  h2, .h2 {
    font-size: 28px;
    line-height: 38px;
  }

  h3, .h3 {
    font-size: 24px;
    line-height: 34px;
  }

  h4, .h4 {
    font-size: 20px;
    line-height: 30px;
  }

  h5, .h5 {
    font-size: 18px;
    line-height: 28px;
  }

  h6, .h6 {
    font-size: 16px;
    line-height: 26px;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (width >= 1750px) {
  .container {
    max-width: 1750px;
  }
}

.prose {
  color: #34b878;
  max-width: 65ch;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: #9282e5;
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(a):not(:where([class~="not-prose"] *)):hover {
  color: #9282e5;
  text-decoration: none;
}

.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-left: 0;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-left-width: .25rem;
  border-left-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: .5rem;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding: .857143em 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"] *)) {
  table-layout: auto;
  text-align: left;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-bottom: .571429em;
  padding-left: .571429em;
  padding-right: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"] *)) {
  vertical-align: top;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(video):not(:where([class~="not-prose"] *)), .prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: .375em;
}

.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7' width='10' height='7'%3E%3Crect fill='%23e52233' x='1' width='8' height='0.5' /%3E%3Crect fill='%23e52233' x='1' y='6' width='8' height='0.5' /%3E%3Crect fill='%23e52233' y='3.9' width='10' height='0.5' /%3E%3Crect fill='%23e52233' y='1.9' width='10' height='0.5' /%3E%3C/svg%3E");
  background-position: 0;
  background-repeat: no-repeat;
  margin-top: 0;
  margin-bottom: .25rem;
  padding-left: 1.75rem;
  list-style-type: none;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(hr + *):not(:where([class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"] *)) {
  padding: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-right-2 {
  right: -.5rem;
}

.-right-full {
  right: -100%;
}

.-top-0 {
  top: 0;
}

.-top-0\.5 {
  top: -.125rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-2 {
  top: -.5rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-10 {
  left: 2.5rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-1\/2 {
  top: 50%;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-\[57px\] {
  top: 57px;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-50 {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-ml-px {
  margin-left: -1px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-\[54px\] {
  margin-right: 54px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-px {
  margin-top: 1px;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-\[calc\(100\%\+2rem\)\] {
  height: calc(100% + 2rem);
}

.h-\[calc\(100vh-32px\)\] {
  height: calc(100vh - 32px);
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-max {
  height: max-content;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-full {
  max-height: 100%;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-11 {
  min-height: 2.75rem;
}

.min-h-12 {
  min-height: 3rem;
}

.min-h-48 {
  min-height: 12rem;
}

.min-h-6 {
  min-height: 1.5rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-\[139px\] {
  min-height: 139px;
}

.min-h-\[48px\] {
  min-height: 48px;
}

.min-h-\[55px\] {
  min-height: 55px;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen, .min-h-vh {
  min-height: 100vh;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-4 {
  width: 1rem;
}

.w-4\.5 {
  width: 1.1rem;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[260px\] {
  width: 260px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[calc\(100\%\+2rem\)\] {
  width: calc(100% + 2rem);
}

.w-\[calc\(100\%-1\.5rem\)\] {
  width: calc(100% - 1.5rem);
}

.w-\[calc\(100vw-2rem\)\] {
  width: calc(100vw - 2rem);
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-px {
  width: 1px;
}

.min-w-0 {
  min-width: 0;
}

.min-w-\[120px\] {
  min-width: 120px;
}

.min-w-\[12rem\] {
  min-width: 12rem;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[260px\] {
  min-width: 260px;
}

.min-w-\[44px\] {
  min-width: 44px;
}

.min-w-\[60px\] {
  min-width: 60px;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-\[140px\] {
  max-width: 140px;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-\[206px\] {
  max-width: 206px;
}

.max-w-\[300px\] {
  max-width: 300px;
}

.max-w-\[320px\] {
  max-width: 320px;
}

.max-w-\[892px\] {
  max-width: 892px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-1 {
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2\.5 {
  --tw-translate-y: .625rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-px {
  gap: 1px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-2\.5 {
  column-gap: .625rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-divide-opacity));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity));
}

.divide-gray-light > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(248 248 248 / var(--tw-divide-opacity));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-5 {
  border-width: 5px;
}

.border-8 {
  border-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-3 {
  border-top-width: 3px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(229 34 51 / var(--tw-border-opacity));
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-dark {
  --tw-border-opacity: 1;
  border-color: rgb(169 169 169 / var(--tw-border-opacity));
}

.border-gray-light {
  --tw-border-opacity: 1;
  border-color: rgb(248 248 248 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.border-primary-dark {
  --tw-border-opacity: 1;
  border-color: rgb(42 153 105 / var(--tw-border-opacity));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(146 130 229 / var(--tw-border-opacity));
}

.border-secondary-light {
  --tw-border-opacity: 1;
  border-color: rgb(172 157 231 / var(--tw-border-opacity));
}

.border-ternary {
  --tw-border-opacity: 1;
  border-color: rgb(36 36 36 / var(--tw-border-opacity));
}

.border-ternary-light {
  --tw-border-opacity: 1;
  border-color: rgb(58 58 58 / var(--tw-border-opacity));
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: rgb(255 152 0 / var(--tw-border-opacity));
}

.border-b-gray-light {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(248 248 248 / var(--tw-border-opacity));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.border-l-secondary {
  --tw-border-opacity: 1;
  border-left-color: rgb(146 130 229 / var(--tw-border-opacity));
}

.border-t-gray-light {
  --tw-border-opacity: 1;
  border-top-color: rgb(248 248 248 / var(--tw-border-opacity));
}

.border-t-primary {
  --tw-border-opacity: 1;
  border-top-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.border-t-primary-dark {
  --tw-border-opacity: 1;
  border-top-color: rgb(42 153 105 / var(--tw-border-opacity));
}

.border-t-secondary {
  --tw-border-opacity: 1;
  border-top-color: rgb(146 130 229 / var(--tw-border-opacity));
}

.border-t-ternary {
  --tw-border-opacity: 1;
  border-top-color: rgb(36 36 36 / var(--tw-border-opacity));
}

.border-t-white {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-black\/25 {
  background-color: #00000040;
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(229 34 51 / var(--tw-bg-opacity));
}

.bg-error-light {
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 235 / var(--tw-bg-opacity));
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.bg-gray-light {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(52 184 120 / var(--tw-bg-opacity));
}

.bg-primary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(242 251 246 / var(--tw-bg-opacity));
}

.bg-primary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 237 224 / var(--tw-bg-opacity));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(42 153 105 / var(--tw-bg-opacity));
}

.bg-primary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(134 225 181 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.bg-secondary-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 253 / var(--tw-bg-opacity));
}

.bg-secondary-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 222 242 / var(--tw-bg-opacity));
}

.bg-secondary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(172 157 231 / var(--tw-bg-opacity));
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(64 198 43 / var(--tw-bg-opacity));
}

.bg-success-light {
  --tw-bg-opacity: 1;
  background-color: rgb(208 240 202 / var(--tw-bg-opacity));
}

.bg-ternary-light {
  --tw-bg-opacity: 1;
  background-color: rgb(58 58 58 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(255 152 0 / var(--tw-bg-opacity));
}

.bg-warning-light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 249 191 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/50 {
  background-color: #ffffff80;
}

.bg-opacity-25 {
  --tw-bg-opacity: .25;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.object-cover {
  object-fit: cover;
}

.object-scale-down {
  object-fit: scale-down;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.\!px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.\!py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4\.5 {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-\[14px\] {
  padding-bottom: 14px;
}

.pb-\[7px\] {
  padding-bottom: 7px;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[15px\] {
  padding-top: 15px;
}

.pt-\[22px\] {
  padding-top: 22px;
}

.pt-\[4px\] {
  padding-top: 4px;
}

.pt-\[60px\] {
  padding-top: 60px;
}

.pt-px {
  padding-top: 1px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 24px;
  line-height: 34px;
}

.text-4xl {
  font-size: 60px;
  line-height: 70px;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-lg {
  font-size: 15px;
  line-height: 18px;
}

.text-md {
  font-size: 14px;
  line-height: 18px;
}

.text-sm {
  font-size: 13px;
  line-height: 18px;
}

.text-xl {
  font-size: 19px;
  line-height: 30px;
}

.text-xs {
  font-size: 12px;
  line-height: 18px;
}

.font-bold {
  font-weight: 700;
}

.font-bold-2 {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-3 {
  line-height: .75rem;
}

.leading-none {
  line-height: 1;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(229 34 51 / var(--tw-text-opacity));
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(222 222 222 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(118 118 118 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(67 67 67 / var(--tw-text-opacity));
}

.text-gray-dark {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
}

.text-gray-light {
  --tw-text-opacity: 1;
  color: rgb(248 248 248 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(52 184 120 / var(--tw-text-opacity));
}

.text-primary-dark {
  --tw-text-opacity: 1;
  color: rgb(42 153 105 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(146 130 229 / var(--tw-text-opacity));
}

.text-secondary-dark {
  --tw-text-opacity: 1;
  color: rgb(94 80 148 / var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(64 198 43 / var(--tw-text-opacity));
}

.text-ternary {
  --tw-text-opacity: 1;
  color: rgb(36 36 36 / var(--tw-text-opacity));
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(255 152 0 / var(--tw-text-opacity));
}

.text-warning-light {
  --tw-text-opacity: 1;
  color: rgb(255 249 191 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(36 36 36 / var(--tw-placeholder-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-3 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-error-light {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 233 235 / var(--tw-ring-opacity));
}

.ring-gray {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(222 222 222 / var(--tw-ring-opacity));
}

.ring-gray-dark {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(169 169 169 / var(--tw-ring-opacity));
}

.ring-gray-light {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 248 248 / var(--tw-ring-opacity));
}

.ring-primary-light {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 225 181 / var(--tw-ring-opacity));
}

.ring-secondary {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(146 130 229 / var(--tw-ring-opacity));
}

.ring-secondary-light {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(172 157 231 / var(--tw-ring-opacity));
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 4px 24px #3c3a6c29);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px #0000000a) drop-shadow(0 4px 3px #0000001a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sidebar {
  --tw-drop-shadow: drop-shadow(0px 0px 24px #1b2e5e0d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0px 8px 24px #1a2d5e1a);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.center-x {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  left: 50%;
}

.center-y {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
}

.center {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
  left: 50%;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.scroll-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.\[text-shadow\:_0_0_3px_rgb\(255_255_255_\/_20\%\)\] {
  text-shadow: 0 0 3px #fff3;
}

.placeholder\:text-gray-dark::placeholder {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:-bottom-1:before {
  content: var(--tw-content);
  bottom: -.25rem;
}

.before\:left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:z-10:before {
  content: var(--tw-content);
  z-index: 10;
}

.before\:-mx-\[12px\]:before {
  content: var(--tw-content);
  margin-left: -12px;
  margin-right: -12px;
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:h-0\.5:before {
  content: var(--tw-content);
  height: .125rem;
}

.before\:h-\[calc\(100\%-12px\)\]:before {
  content: var(--tw-content);
  height: calc(100% - 12px);
}

.before\:h-px:before {
  content: var(--tw-content);
  height: 1px;
}

.before\:w-\[calc\(100\%\+24px\)\]:before {
  content: var(--tw-content);
  width: calc(100% + 24px);
}

.before\:w-full:before {
  content: var(--tw-content);
  width: 100%;
}

.before\:rounded:before {
  content: var(--tw-content);
  border-radius: .25rem;
}

.before\:bg-error-light:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(252 233 235 / var(--tw-bg-opacity));
}

.before\:bg-gray:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.before\:bg-secondary:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.before\:center-y:before {
  content: var(--tw-content);
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 50%;
}

.odd\:bg-white:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.even\:bg-gray-light:nth-child(2n) {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.checked\:bg-secondary:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.focus-within\:border-error:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(229 34 51 / var(--tw-border-opacity));
}

.focus-within\:border-primary:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.focus-within\:outline-none:focus-within {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus-within\:ring-3:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-error-light:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 233 235 / var(--tw-ring-opacity));
}

.focus-within\:ring-primary-light:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 225 181 / var(--tw-ring-opacity));
}

.hover\:border-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.hover\:border-secondary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(146 130 229 / var(--tw-border-opacity));
}

.hover\:bg-error-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(214 26 42 / var(--tw-bg-opacity));
}

.hover\:bg-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.hover\:bg-gray-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.hover\:bg-primary-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(214 237 224 / var(--tw-bg-opacity));
}

.hover\:bg-primary-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(42 153 105 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 253 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(94 80 148 / var(--tw-bg-opacity));
}

.hover\:bg-secondary-light:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(172 157 231 / var(--tw-bg-opacity));
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:text-black\/70:hover {
  color: #000000b3;
}

.hover\:text-primary-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(42 153 105 / var(--tw-text-opacity));
}

.hover\:text-secondary:hover {
  --tw-text-opacity: 1;
  color: rgb(146 130 229 / var(--tw-text-opacity));
}

.hover\:text-secondary-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(94 80 148 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.checked\:hover\:bg-secondary:hover:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.focus\:border-error:focus {
  --tw-border-opacity: 1;
  border-color: rgb(229 34 51 / var(--tw-border-opacity));
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(52 184 120 / var(--tw-border-opacity));
}

.focus\:border-secondary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(146 130 229 / var(--tw-border-opacity));
}

.focus\:border-ternary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(36 36 36 / var(--tw-border-opacity));
}

.focus\:border-b-primary-dark:focus {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(42 153 105 / var(--tw-border-opacity));
}

.focus\:bg-gray-light:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.focus\:bg-primary-dark:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(42 153 105 / var(--tw-bg-opacity));
}

.focus\:bg-secondary-dark:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(94 80 148 / var(--tw-bg-opacity));
}

.focus\:bg-secondary-light:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(172 157 231 / var(--tw-bg-opacity));
}

.focus\:text-black\/70:focus {
  color: #000000b3;
}

.focus\:text-secondary:focus {
  --tw-text-opacity: 1;
  color: rgb(146 130 229 / var(--tw-text-opacity));
}

.focus\:underline:focus {
  text-decoration-line: underline;
}

.focus\:no-underline:focus {
  text-decoration-line: none;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-3:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-error-light:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 233 235 / var(--tw-ring-opacity));
}

.focus\:ring-gray-light:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(248 248 248 / var(--tw-ring-opacity));
}

.focus\:ring-primary-dark:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(42 153 105 / var(--tw-ring-opacity));
}

.focus\:ring-primary-light:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(134 225 181 / var(--tw-ring-opacity));
}

.focus\:ring-secondary-light:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(172 157 231 / var(--tw-ring-opacity));
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.checked\:focus\:bg-secondary:focus:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-gray:disabled {
  --tw-border-opacity: 1;
  border-color: rgb(222 222 222 / var(--tw-border-opacity));
}

.disabled\:bg-gray:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.disabled\:bg-gray-light:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.disabled\:bg-primary:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(52 184 120 / var(--tw-bg-opacity));
}

.disabled\:bg-primary-light:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(134 225 181 / var(--tw-bg-opacity));
}

.disabled\:bg-secondary:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(146 130 229 / var(--tw-bg-opacity));
}

.disabled\:bg-white:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.disabled\:text-gray-dark:disabled {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
}

.disabled\:opacity-30:disabled {
  opacity: .3;
}

.disabled\:opacity-60:disabled {
  opacity: .6;
}

.disabled\:opacity-80:disabled {
  opacity: .8;
}

.checked\:disabled\:bg-gray:disabled:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.disabled\:hover\:bg-transparent:hover:disabled {
  background-color: #0000;
}

.checked\:disabled\:hover\:bg-gray:hover:disabled:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(222 222 222 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:border-secondary-light {
  --tw-border-opacity: 1;
  border-color: rgb(172 157 231 / var(--tw-border-opacity));
}

.group:hover .group-hover\:text-secondary-dark {
  --tw-text-opacity: 1;
  color: rgb(94 80 148 / var(--tw-text-opacity));
}

.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:no-underline, .group:focus .group-focus\:no-underline {
  text-decoration-line: none;
}

.group:disabled .group-disabled\:text-gray-dark {
  --tw-text-opacity: 1;
  color: rgb(169 169 169 / var(--tw-text-opacity));
}

.group:disabled .group-disabled\:no-underline {
  text-decoration-line: none;
}

@media (width >= 640px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:left-\[unset\] {
    left: unset;
  }

  .sm\:right-full {
    right: 100%;
  }

  .sm\:top-2 {
    top: .5rem;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mr-\[68px\] {
    margin-right: 68px;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-80 {
    height: 20rem;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:w-\[335px\] {
    width: 335px;
  }

  .sm\:w-\[360px\] {
    width: 360px;
  }

  .sm\:w-\[90px\] {
    width: 90px;
  }

  .sm\:min-w-\[90px\] {
    min-width: 90px;
  }

  .sm\:max-w-\[420px\] {
    max-width: 420px;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-6 {
    gap: 1.5rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:bg-gray-light {
    --tw-bg-opacity: 1;
    background-color: rgb(248 248 248 / var(--tw-bg-opacity));
  }

  .sm\:bg-transparent {
    background-color: #0000;
  }

  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 15px;
    line-height: 18px;
  }

  .sm\:text-xl {
    font-size: 19px;
    line-height: 30px;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .sm\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 4px 24px #3c3a6c29);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
}

@media (width >= 768px) {
  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:text-lg {
    font-size: 15px;
    line-height: 18px;
  }

  .md\:text-md {
    font-size: 14px;
    line-height: 18px;
  }

  .md\:text-xl {
    font-size: 19px;
    line-height: 30px;
  }
}

@media (width >= 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-8 {
    gap: 2rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:-right-2 {
    right: -.5rem;
  }

  .xl\:-top-2 {
    top: -.5rem;
  }

  .xl\:top-0 {
    top: 0;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:h-5 {
    height: 1.25rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:h-auto {
    height: auto;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-5 {
    width: 1.25rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:gap-12 {
    gap: 3rem;
  }

  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }

  .xl\:rounded-md {
    border-radius: .375rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:px-4\.5 {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-2xl {
    font-size: 24px;
    line-height: 34px;
  }

  .xl\:text-\[32px\] {
    font-size: 32px;
  }

  .xl\:text-lg {
    font-size: 15px;
    line-height: 18px;
  }

  .xl\:text-xl {
    font-size: 19px;
    line-height: 30px;
  }

  .xl\:text-xs {
    font-size: 12px;
    line-height: 18px;
  }

  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .xl\:focus-within\:ring-3:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .xl\:focus\:border:focus {
    border-width: 1px;
  }

  .xl\:focus\:ring-3:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }
}

@media (width >= 1536px) {
  .\32 xl\:w-1\/2 {
    width: 50%;
  }

  .\32 xl\:w-1\/3 {
    width: 33.3333%;
  }

  .\32 xl\:w-2\/3 {
    width: 66.6667%;
  }

  .\32 xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .\32 xl\:flex-row {
    flex-direction: row;
  }

  .\32 xl\:py-\[5px\] {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
/*# sourceMappingURL=index.css.map */
