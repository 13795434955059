@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import './assets/calendar.css';
@import './assets/juBESy.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased;
    @apply font-base;
    @apply text-gray-800;
    @apply leading-relaxed;
    @apply text-lg;
    @apply bg-secondary-100;
  }

  h1, .h1 {
    font-size: 28px;
    line-height: 38px;
    font-weight: bold;
  }

  h2, .h2 {
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
  }

  h3, .h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
  }

  h4, .h4 {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
  }

  h5, .h5 {
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
  }

  h6, .h6 {
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
  }

  @screen lg {
    h1, .h1 {
      font-size: 32px;
      line-height: 42px;
    }

    h2, .h2 {
      font-size: 28px;
      line-height: 38px;
    }

    h3, .h3 {
      font-size: 24px;
      line-height: 34px;
    }

    h4, .h4 {
      font-size: 20px;
      line-height: 30px;
    }

    h5, .h5 {
      font-size: 18px;
      line-height: 28px;
    }

    h6, .h6 {
      font-size: 16px;
      line-height: 26px;
    }
  }
}


@layer utilities {
  .center-x {
    @apply left-1/2 translate-x-[-50%];
  }

  .center-y {
    @apply top-1/2 translate-y-[-50%];
  }

  .center {
    @apply center-x center-y;
  }

  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }

  .scroll-hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
